<template>
    <div class="RentalService">
        <p class="p justify">
            为更好地服务于科研人员，复数智科提供超算机时的租赁服务。超算机时资源丰富，规模庞大，包含CPU机时、GPU机时、大内存机时等。全新的超级计算机系统能运行大规模的并行计算程序，高性能的存储系统可提供高达数十TB的数据存储服务，更有专业的工程师团队为您提供全方面的支持和服务，我们针对每一位客户的实际情况和应用场景推荐优质的机时资源和方案供选择。
        </p>
        <b-row align-h="center">
            <b-col
                cols="10"
                class="text-center"
            >
                <p class="table-title">
                    集群和分区信息
                </p>
            </b-col>
            <b-col
                cols="12"
                style="padding:0"
            >
                <div class="table-div">
                    <table
                        class="table"
                        border="1"
                    >
                        <thead>
                            <tr>
                                <th width="80">
                                    集群
                                </th>
                                <th width="100">
                                    分区全名
                                </th>
                                <th width="90">
                                    分区节点数
                                </th>
                                <th width="100">
                                    单节点核心数
                                </th>
                                <th width="100">
                                    单节点GPU数
                                </th>
                                <th width="80">
                                    单节点内存（MB）
                                </th>
                                <th width="60">
                                    QOS
                                </th>
                                <th width="150">
                                    说明
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- c028m0128g -->
                            <tr>
                                <th rowspan="12">
                                    hpc01
                                </th>
                                <td rowspan="3">
                                    c028m0128g
                                </td>
                                <td rowspan="3">
                                    40
                                </td>
                                <td rowspan="3">
                                    28
                                </td>
                                <td rowspan="3">
                                    0
                                </td>
                                <td rowspan="3">
                                    129000
                                </td>
                                <td rowspan="1">
                                    low
                                </td>
                                <td rowspan="3">
                                    包括c[101-140]，128G内存CPU节点
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    normal
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    high
                                </td>
                            </tr>
                            <!-- c028m0064g -->
                            <tr>
                                <td rowspan="3">
                                    c028m0064g
                                </td>
                                <td rowspan="3">
                                    158
                                </td>
                                <td rowspan="3">
                                    28
                                </td>
                                <td rowspan="3">
                                    0
                                </td>
                                <td rowspan="3">
                                    63000
                                </td>
                                <td rowspan="1">
                                    low
                                </td>
                                <td rowspan="3">
                                    包括c[001-100,141-198]，64G内存CPU节点
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    normal
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    high
                                </td>
                            </tr>
                            <!-- compute -->
                            <tr>
                                <td rowspan="3">
                                    compute
                                </td>
                                <td rowspan="3">
                                    198
                                </td>
                                <td rowspan="3">
                                    28
                                </td>
                                <td rowspan="3">
                                    0
                                </td>
                                <td rowspan="3">
                                    63000
                                </td>
                                <td rowspan="1">
                                    low
                                </td>
                                <td rowspan="3">
                                    包括c[001-198] 所有CPU节点分区，提交到该分区的作业会随机分配到64G内存节点或128G内存节点
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    normal
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    high
                                </td>
                            </tr>
                            <!-- gpu -->
                            <tr>
                                <td rowspan="3">
                                    gpu
                                </td>
                                <td rowspan="3">
                                    1
                                </td>
                                <td rowspan="3">
                                    48
                                </td>
                                <td rowspan="3">
                                    8
                                </td>
                                <td rowspan="3">
                                    386000
                                </td>
                                <td rowspan="1">
                                    low
                                </td>
                                <td rowspan="3">
                                    包括gpu01，GPU节点
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    normal
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="1">
                                    high
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
  name: 'RentalService',
  data () {
        return {
        }
      },
      methods: {
      },
      created () {
        this.$emit('contentTitle', '超算机时租赁')
      }
}
</script>
<style lang="less" scoped>
.RentalService{
    margin-bottom:6.25rem;
    .table-div{
      overflow-x: auto;
      .table{
        width: 100%;
        height: auto;
        table-layout:fixed;
        text-align: center;
        border-collapse: collapse;
        thead{
          background:rgba(0, 0, 0, 0.85);
          tr{
            th{
              color: #fff;
              border-right: 1px solid #fff;
              font-size: 0.75rem;
            }
          }
        }
        tbody{
          background: #d7d7d7;
          tr{
            th,td{
              vertical-align: middle!important;
              border-right: 1px solid #fff;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    .table-title{
      font-size: 1.125rem;
       color: rgba(0,0,0,.65);
       font-weight: 600;
    }
}
</style>
